.skills-large-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 40px 0;
}

.skills-large-title {
    font-size: 30px;
}

.skills-large-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skills-large-item {
    width: 80%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px 0px;
  }

  .skills-large-item-icon {
    width: 100px;
    height: 100px;
  }
  .skills-large-item-title {
    font-size: 20px;
  }
  .skills-large-item-subtitle {
    font-size: 12px;
  }


@media only screen and (min-width: 768px) {
  .skills-large-container {
    width: 80%;
    height: 80vh;
    margin: 40px auto 0;
    display: flex;
    flex-direction: column;
  }

  .skills-large-title {
    font-size: 40px;
  }

  .skills-large-list-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .skills-large-item {
    width: 80%;
    height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0px;
  }

  .skills-large-item-icon {
    width: 150px;
    height: 150px;
  }
  .skills-large-item-content {
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .skills-large-item-title {
    font-size: 30px;
  }
  .skills-large-item-subtitle {
    font-size: 17px;
  }
}
